<template>
  <component :is="svgComponent" :class="'svg-'+size" v-bind="$attrs" />
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    required: true
  }
})

const svgComponent = computed(() => {
  return defineAsyncComponent(() => import(`@/assets/icons/duotone/${props.name}.svg`))
})
</script>
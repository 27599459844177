
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutOS7HpsGFDFSTbbd4iXDP92Iuvckam0GQw5dgZEXCgBIMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as trust2mVnnBYNrQFHcbx7wXddRdU4QS0UNLQv_45kQ_45UPHB8AcMeta } from "/vercel/path0/pages/trust.vue?macro=true";
import { default as _91slug_93klqvwrwgFngOjIlRZdOv6Y2i_xtuPW6CDzziWpX_bicMeta } from "/vercel/path0/pages/[slug].vue?macro=true";
import { default as contact6cZoOGxJBTY9l_45DSf37OWQKprIBU1PffJx7PVn14dCsMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as pricingPPf6BeWRpD7HVrIl3nqmmYLxmslMZFDdF0SKMveqHGcMeta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as securityuHjkZLuO5vf_NHAidFHXMxH22Wm1EKFolnwkP8TUZPAMeta } from "/vercel/path0/pages/security.vue?macro=true";
import { default as book_45demoHUQ43jdvsS5DsN9edLpvfzQMOHe47aEjSktkx1TycXIMeta } from "/vercel/path0/pages/book-demo.vue?macro=true";
import { default as indexDIlA_VwVYEsF82JYYTptIRBexsOVSbsbgl1xBQ_lIIoMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as _91slug_93v3ez11IMRk2feKAiBpS9mVspk4W7_E8tt32O22_45Wq2cMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93jfjV3UMrcxel5IS7527Y4NwdNs8LC_45VWbd2yjM8LhQgMeta } from "/vercel/path0/pages/portal/[slug].vue?macro=true";
import { default as indexS6wCCZ0l7nzAespPrlly2ctZiIjNk2hjqmDmykPHvpQMeta } from "/vercel/path0/pages/features/index.vue?macro=true";
import { default as indexTyHPfFIDixVL670mB1ENepYVkMwp675_28IaYOQhrg8Meta } from "/vercel/path0/pages/webinars/index.vue?macro=true";
import { default as _91slug_933okxONYrOqvv1SuPlUWNMR0KQofkth8FTJlmwJHvJ0sMeta } from "/vercel/path0/pages/articles/[slug].vue?macro=true";
import { default as _91slug_936dPUAljRpzTLu7VSgzKFqRNhVkSgmm_pRpoBjwl2nzwMeta } from "/vercel/path0/pages/policies/[slug].vue?macro=true";
import { default as _91slug_93Wk9zRxAtq3NMg4ZQAUdN42VC9IeBiqNrANEIWTiOuRYMeta } from "/vercel/path0/pages/webinars/[slug].vue?macro=true";
import { default as _91slug_93V0EDcaRiAI1CuFVKevHn0tlTDOd29VC1Al2AJDeFroEMeta } from "/vercel/path0/pages/solutions/[slug].vue?macro=true";
import { default as _91slug_934_40BSAHy5jDyo4R9bi0HIjJnxG2leBxkKFNnBhScm4Meta } from "/vercel/path0/pages/use-cases/[slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "trust",
    path: "/trust",
    component: () => import("/vercel/path0/pages/trust.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/vercel/path0/pages/[slug].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/vercel/path0/pages/pricing.vue")
  },
  {
    name: "security",
    path: "/security",
    component: () => import("/vercel/path0/pages/security.vue")
  },
  {
    name: "book-demo",
    path: "/book-demo",
    component: () => import("/vercel/path0/pages/book-demo.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue")
  },
  {
    name: "portal-slug",
    path: "/portal/:slug()",
    meta: _91slug_93jfjV3UMrcxel5IS7527Y4NwdNs8LC_45VWbd2yjM8LhQgMeta || {},
    component: () => import("/vercel/path0/pages/portal/[slug].vue")
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/vercel/path0/pages/features/index.vue")
  },
  {
    name: "webinars",
    path: "/webinars",
    component: () => import("/vercel/path0/pages/webinars/index.vue")
  },
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    component: () => import("/vercel/path0/pages/articles/[slug].vue")
  },
  {
    name: "policies-slug",
    path: "/policies/:slug()",
    component: () => import("/vercel/path0/pages/policies/[slug].vue")
  },
  {
    name: "webinars-slug",
    path: "/webinars/:slug()",
    component: () => import("/vercel/path0/pages/webinars/[slug].vue")
  },
  {
    name: "solutions-slug",
    path: "/solutions/:slug()",
    component: () => import("/vercel/path0/pages/solutions/[slug].vue")
  },
  {
    name: "use-cases-slug",
    path: "/use-cases/:slug()",
    component: () => import("/vercel/path0/pages/use-cases/[slug].vue")
  }
]
export default defineNuxtPlugin(nuxtApp => {

  const route = useRoute()
  if (route.path.includes('/portal/')) {
    return
  }

  const router = useRouter()
  const { gtmId } = useRuntimeConfig().public
  
  window.dataLayer = window.dataLayer || []  

  window.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': Date.now()
  })

  router.afterEach((to) => {
    window.dataLayer.push({
      event: 'pageview',
      page: to.fullPath
    })
  })
  
  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtm.js?id=${gtmId}`,
        async: true,
      },
    ],
  })
})
export default defineNuxtPlugin(() => {
  return {
    provide: {
      isMob: () => {
        const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
        ]
        return toMatch.some(toMatchItem => {
          return navigator.userAgent.match(toMatchItem)
        })
      }
    }
  }
})
export const useOptionsStore = defineStore('options-store', {

  persist: true,

  state: () => {
    return {
      searchActive: false
    }
  },

  actions: {
    closeSearch() {
      this.searchActive = false
    },
    openSearch() {
      this.searchActive = true
    }
  }



})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOptionsStore, import.meta.hot))
}

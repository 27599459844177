const defaultTitle = 'Elker - Anonymous Reporting for Employees to Speak Up'
const defaultDescription = 'Elker is a speak-up and whistleblowing platform, empowering employees to make their voices heard, anonymously and safely.'
const defaultImage = 'https://media.elker.com/5cfeaa8c-2d96-4272-b445-14aa107c5311/speak-up-static.png'


function getImageUrl(imageObj) {
  if (!imageObj) return defaultImage
  
  if (typeof imageObj === 'string') return imageObj
  
  if (imageObj.id && imageObj.filename_download) {
    return `https://media.elker.com/${imageObj.id}/${imageObj.filename_download}`
  }
  
  return defaultImage
}


function useSocialMeta(content, url) {
  const { title, description, image } = content
  const imageUrl = getImageUrl(image)
  const fullUrl = `https://elker.com${url === '/' ? '' : url}`
  
  return [
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: description,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: imageUrl,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: fullUrl,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'Elker',
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: 'website',
    },
    
    // Twitter meta
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title,
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description,
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: imageUrl,
    },
    {
      hid: 'twitter:site',
      name: 'twitter:site',
      content: '@ElkerApp',
    },
  ]
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      seo: (meta, url, options = {}) => {
        if (!meta) {
          return {
            title: defaultTitle,
            meta: [
              {
                hid: 'description',
                name: 'description',
                content: defaultDescription,
              },
              ...useSocialMeta({
                title: defaultTitle,
                description: defaultDescription,
                image: null
              }, url),
            ],
          }
        }
        
        const { meta_title, meta_description, meta_image } = meta
        
        const title = meta_title || defaultTitle
        const description = meta_description || defaultDescription
        
        const socialMetas = useSocialMeta({
          title,
          description,
          image: meta_image
        }, url)
        
        const canonicalHref = options.forceCanonical || `https://elker.com${url === '/' ? '' : url}`
        
        return {
          title,
          link: [
            {
              hid: 'canonical',
              rel: 'canonical',
              href: canonicalHref,
            },
          ],
          meta: [
            {
              hid: 'description',
              name: 'description',
              content: description,
            },
            ...socialMetas,
          ],
        }
      }
    }
  }
})
import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_K8lP_8b98HH4HbmBFPSuqVp02ekVSu2mCShINDsaRV4 from "/vercel/path0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import dist_plugin_7bff902c_S9c6zFu5vn2gXuyFcVuKwxhCN6TefzO1dT1N1VS12zU from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/dist.plugin.7bff902c.mjs";
import plugin_75c5JB2dypWkszlGRvY60nSlGsfjcTznvhVler6Mq2M from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4 from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import Vue3Marquee_client_XZmi5_Yiggm_wq4nGy6OEW1XTG0sCCqRmbC_bKAY4xw from "/vercel/path0/plugins/Vue3Marquee.client.js";
import dottiePlayer_client_WMfz90NOCeeOwLGXWXVikaE2uIf__1U7gIiO6z6Gu6c from "/vercel/path0/plugins/dottiePlayer.client.js";
import gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg from "/vercel/path0/plugins/gtm.client.js";
import isMob_client_CnCJaEzV47ZHN7g0B7iDk03GhTwsyxikHTUJOxzCIqE from "/vercel/path0/plugins/isMob.client.js";
import seo_6O_MUlqQcAtAbfWfpsSfp_bfRLi46n22fhBYwD5h91A from "/vercel/path0/plugins/seo.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_K8lP_8b98HH4HbmBFPSuqVp02ekVSu2mCShINDsaRV4,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  dist_plugin_7bff902c_S9c6zFu5vn2gXuyFcVuKwxhCN6TefzO1dT1N1VS12zU,
  plugin_75c5JB2dypWkszlGRvY60nSlGsfjcTznvhVler6Mq2M,
  plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4,
  Vue3Marquee_client_XZmi5_Yiggm_wq4nGy6OEW1XTG0sCCqRmbC_bKAY4xw,
  dottiePlayer_client_WMfz90NOCeeOwLGXWXVikaE2uIf__1U7gIiO6z6Gu6c,
  gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg,
  isMob_client_CnCJaEzV47ZHN7g0B7iDk03GhTwsyxikHTUJOxzCIqE,
  seo_6O_MUlqQcAtAbfWfpsSfp_bfRLi46n22fhBYwD5h91A
]